import tw, { styled } from 'twin.macro';

const StyledVendorDetails = styled.section`
${tw`md:(py-20) py-4 bg-primary bg-opacity-5 relative overflow-hidden`}
.container {
    ${tw`grid grid-cols-1 lg:(px-16 grid-cols-2 py-5 gap-8) xl:(px-24 grid-cols-3) container mx-auto px-4 gap-6 bg-transparent text-black`} 
    .card-wrapper {
        ${tw`bg-white shadow-lg rounded-lg flex flex-col overflow-hidden relative [border: 1px solid #ddd]`}

        figure {
            ${tw`w-full overflow-hidden relative`}

            img {
                ${tw`w-full h-60 [transform: translateZ(0)] [transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms]`}
            }

            &::after {
                ${tw`[content: ''] h-full w-full bottom-0 top-0 absolute z-[1] [background-image: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.5))]`}
            }
        }

        .content {
            ${tw`flex flex-col p-6 gap-3 text-[#4a4a4a] mb-20`}

            h2 {
                ${tw`text-xl font-medium text-[#4a4a4a]`}
            }

            .address {
                ${tw`flex flex-row items-center text-[#4a4a4a]`}

                .icon {
                    ${tw`mr-0.5 shrink-0 text-primary`}
                }
            }

            .tags {
                ${tw`text-sm`}
            }
        }

        .contact-info-wrapper {
            ${tw`absolute bottom-0 left-0 right-0 p-4 bg-[#f4f4f4]`}
            .contact-info {
                ${tw`flex flex-row justify-between items-center`}
        
                .left {
                    ${tw`flex flex-row gap-4 items-center pr-6`}
                    h3 {
                        ${tw`font-medium [word-wrap: break-word]`}
                    }
                }
        
                .right {
                    ${tw`flex items-center justify-center relative gap-4`}
        
                    .icon-wrapper {
                        ${tw`w-9 h-9 rounded-full bg-[#25D366] z-[1] flex items-center justify-center`}
                        .icon {
                            ${tw`text-white text-base`}
                        }
        
                        &.phone {
                            ${tw`bg-primary`}
                        }
                    }
        
                    &:before {
                        ${tw`[content: ""] absolute bg-[#f9f9f9] rounded-l-xl -top-2 -bottom-2 -right-4 -left-5 border border-[#eee] border-r-0`}
                    }
                }
            }
        }

        &:hover {
            ${tw`[transform: translate3d(0, -2px, 0)]`}
            figure {
                img {
                    ${tw`[transform: scale(1.12)]`}
                }
            }
        }
    }
}
.empty-results {
    ${tw`flex items-center justify-center w-full md:px-2 px-0`}
    h2 {
        ${tw`text-[#333b54] font-bold md:text-xl text-base`}
    }
}
`;

export default StyledVendorDetails;

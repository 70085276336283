import React from "react"
import StyledVendorDetails from "./style/vendors-list"
import { BsWhatsapp } from "react-icons/bs"
import { FiPhoneCall, FiMapPin } from "react-icons/fi"
import ReactWhatsapp from "react-whatsapp"

const VendorsList = ({ data }) => {
  return (
    <StyledVendorDetails>
      {data.length ? (
        <div className="container">
          {data.map(({ frontmatter, fields }, i) => {
            const { slug } = fields;
            const { title, city, contactPerson, contacts, tags, thumbnail } = frontmatter;
            const imgPath = process.env.GATSBY_IMAGE_URL
            const imgURL = `${imgPath}${slug.toLowerCase()}${thumbnail}`
            return (
              <div className="card-wrapper" key={i}>
                <figure>
                  <img src={imgURL} alt="" />
                </figure>
                <div className="content">
                  <h2>
                    <a href={slug} target="_blank" rel="noopener noreferrer">
                      {title}
                    </a>
                  </h2>
                  <div className="address">
                    <a href={contacts.shortenAddress} target="_blank" rel="noopener noreferrer">
                      <FiMapPin className="icon" /> {city}
                    </a>
                  </div>
                  {tags != null &&
                    <div className="tags">
                      {tags.map((item, index) => (
                        <span key={index}>{index > 0 && ", "}{item}</span>
                      ))}
                    </div>
                  }
                </div>
                <div className="contact-info-wrapper">
                  <div className="contact-info">
                    <div className="left">
                      <h3>{contactPerson.name}</h3>
                    </div>
                    <div className="right">
                      <ReactWhatsapp number={contactPerson.whatsNumber} className="icon-wrapper">
                        <BsWhatsapp className="icon" />
                      </ReactWhatsapp>
                      <a href={`tel:${contactPerson.contactNumber}`} className="icon-wrapper phone">
                        <FiPhoneCall className="icon" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      ) : (
        <div className="empty-results">
          <h2>Sorry, no search results match your query.</h2>
        </div>
      )}
    </StyledVendorDetails >
  )
};

export default VendorsList

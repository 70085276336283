import React from "react"
import StyledBanner from "./style/banner"
import serviceData from "../../util/services.json"
import Breadcumb from "../common/Breadcrumb"

const Banner = ({ tag, filterServices }) => {
  const serviceChangeHandler = (e) => {
    filterServices(e);
  };
  const filtered = serviceData.services.filter(service => {
    return (
      service.name === tag
    );
  });
  const imgPath = process.env.GATSBY_IMAGE_URL
  const imgURL = `${imgPath}/main-services/${filtered[0].image}.jpg`
  const crumbs = [
    { name: 'Home', link: '/' },
    { name: tag, link: '' }
  ]
  return (
    <StyledBanner>
      <div className="banner"
        style={{ backgroundImage: `url(${imgURL})` }}>
        <div className="filter-wrapper">
          <div className="main">
            <h1>{tag}</h1>
            <Breadcumb crumbs={crumbs} />
            <div className="search-wrapper">
              <input onChange={serviceChangeHandler} type="search" placeholder="Search by city / vendor / specialities" />
            </div>
          </div>
        </div>
      </div>
    </StyledBanner >
  )
};

export default Banner

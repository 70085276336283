import React, { useState } from 'react'
import { graphql } from 'gatsby'
import Banner from '../components/service-list/Banner'
import Layout from '../components/layout'
import { SEO } from "../components/seo"
import VendorsList from '../components/service-list/VendorsList'
import serviceData from "../util/services.json"

const meta = serviceData.services

const TagsTemplate = ({ pageContext, data }) => {
  const { tag } = pageContext;
  const posts = data.allMarkdownRemark.nodes;
  // console.log(data)
  const emptyQuery = '';
  const [state, setState] = useState({
    filteredServiceList: [],
    query: emptyQuery
  });
  const filterServices = (event) => {
    const query = event.target.value;
    const filteredServiceList = posts.filter((post) => {
      return (
        post.frontmatter.title.toLowerCase().includes(query.toLowerCase()) ||
        (post.frontmatter.city &&
          post.frontmatter.city.toLowerCase().includes(query.toLowerCase())) ||
        (post.frontmatter.tags &&
          post.frontmatter.tags.join('').toLowerCase().includes(query.toLowerCase()))
      );
    });

    // set the component's state with our newly generated query and list variables
    setState({
      query,
      filteredServiceList
    });
  };
  const { filteredServiceList, query } = state;
  const hasSearchResults = filteredServiceList && query !== emptyQuery;
  const servicesList = hasSearchResults ? filteredServiceList : posts;
  return (
    <>
      <Layout>
        <Banner tag={tag} filterServices={(e) => filterServices(e)} />
        {/* <input
          className="searchInput"
          type="search"
          aria-label="Search"
          placeholder="Filter blog posts by title or tag"
          onChange={(e) => filterPosts(e)}
        /> */}
        {/* {totalCount} posts tagged "{tag}" */}
        <VendorsList data={servicesList} query={query} emptyQuery={emptyQuery} />
      </Layout >
    </>
  );
};

export default TagsTemplate;

export const Head = ({ pageContext, serviceData }) => {
  const { tag } = pageContext;
  const result = meta.find(item => item.name.includes(tag));
  return (
    <SEO title={tag} description={result.seo} />
  )
}
export const pageQuery = graphql`
  query($tag: String) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___sort], order: ASC }
      filter: {
        frontmatter: { services: { in: [$tag] } }
      }
    ) {
      totalCount
      nodes {
        fields {
          slug
        }
        frontmatter {
          title
          sort
          city
          thumbnail
          contactPerson {
            name
            whatsNumber
            contactNumber
          }
          contacts {
            shortenAddress
          }
          tags
        }
      }
    }
  }
`;
